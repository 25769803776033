import React, { useCallback, useState } from "react"
import classNames from "classnames"
import { useIntl } from "react-intl"
import { FavoriteContentType } from "../../types/favorite"
import { useFavorites } from "../../hooks/use-favorites"
import { Button } from "../button/button"
import { Share } from "../share/share"
import styles from "./share-and-like-button.module.scss"
import { Toast } from "./toast"
import { Link } from "@components/link/link"
import { AUTH_URL, useLigonierAuth } from "@utils/ligonierAuth"

export interface ShareAndLikeButtonProps {
  id?: string
  contentType?: FavoriteContentType
  shareUrl?: string
  className?: string
}

export const ShareAndLikeButton: React.FunctionComponent<
  ShareAndLikeButtonProps
> = ({ shareUrl, contentType, className, id }) => {
  const [isShareOpen, setShareOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState<string | JSX.Element>("")

  const { formatMessage } = useIntl()
  const user = useLigonierAuth(true)
  const { addFavorite, checkFavorite, removeFavorite } = useFavorites(user)

  const canFavoriteResource = user.authenticated && id && contentType
  const isFavorite = id ? checkFavorite(id) : false

  const handleFavoriteButtonClick = useCallback(async () => {
    if (isFavorite) {
      const result = await removeFavorite(id!)

      if (result) {
        setToastMessage(formatMessage({ id: "general-toast-removed-favorite" }))
      }
    } else {
      const result = await addFavorite(id!, contentType!)

      if (result) {
        setToastMessage(formatMessage({ id: "general-toast-added-favorite" }))
      }
    }
  }, [isFavorite, removeFavorite, id, formatMessage, addFavorite, contentType])

  const createAccount = () => {
    const createAccountMessage = (
      <span>
        <Link
          className={styles.shareAndLikeAuthLinks}
          to={`${AUTH_URL}/authorize?redirectUri=${location.href}&grantType=implicit_cookie`}
        >
          Sign in
        </Link>{" "}
        or{" "}
        <Link
          className={styles.shareAndLikeAuthLinks}
          to={`${AUTH_URL}/register?redirectUri=${location.href}&grantType=implicit_cookie`}
        >
          create an account
        </Link>{" "}
        to favorite items.
      </span>
    )

    setToastMessage(createAccountMessage)
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.icons}>
        {canFavoriteResource ? (
          <Button
            icon={isFavorite ? "12-favorited" : "12-favorite"}
            size="sm"
            className={styles.favoriteButton}
            onClick={handleFavoriteButtonClick}
          />
        ) : (
          <Button
            icon={"12-favorite"}
            size="sm"
            className={styles.favoriteButton}
            onClick={createAccount}
          />
        )}
        <Button
          icon="12-share"
          size="sm"
          onClick={() => setShareOpen(true)}
          className={styles.shareButton}
        />
      </div>
      {isShareOpen && (
        <Share
          onClose={() => setShareOpen(false)}
          title={formatMessage({ id: "general-share-cta" })}
          url={
            shareUrl
              ? `${document.location.origin}${shareUrl}`
              : document.location.href
          }
        />
      )}
      {canFavoriteResource
        ? toastMessage && (
            <Toast
              message={toastMessage}
              actionText={formatMessage({ id: "general-toast-undo" })}
              action={handleFavoriteButtonClick}
            />
          )
        : toastMessage && <Toast message={toastMessage} />}
    </div>
  )
}
