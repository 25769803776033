import axios from "axios"
import { useEffect, useState } from "react"
import { LigonierAuthResponse } from "@utils/ligonierAuth"

export interface UseFavorites {
  addFavorite: (entryId: string, contentType: string) => Promise<boolean>
  checkFavorite: (itemId: string) => boolean
  removeFavorite: (entryId: string) => Promise<boolean>
}

interface ResponseItem {
  entry_id: string
  content_type: string
  id: number
}

const baseUrl = process.env.GATSBY_DJANGO_REDIRECT_URL

const fetchFavorites = async (
  accessToken?: LigonierAuthResponse["accessToken"]
) => {
  if (!accessToken) {
    return
  }

  const fetchFavoritesUrl = `${baseUrl}/api/v1/user/favorite/list/`

  let response

  try {
    response = await axios.get(fetchFavoritesUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  } catch (e) {
    console.log(`addFavorite Error: `, e.message)
    response = null
  }

  const data = (response?.data || []) as ResponseItem[]

  const list = new Map()

  if (Array.isArray(data)) {
    data.forEach(({ entry_id, content_type, id }) => {
      list.set(entry_id, { id, content_type })
    })
  }

  return list
}

export const useFavorites = (user?: LigonierAuthResponse): UseFavorites => {
  const [favorites, setFavorites] = useState(new Map())

  useEffect(() => {
    let isMounted = true

    if (!user?.accessToken) {
      return
    }

    const getFavorites = async () => {
      const favs = await fetchFavorites(user.accessToken)

      if (isMounted && favs) {
        setFavorites(favs)
      }
    }

    getFavorites()

    return () => {
      isMounted = false
    }
  }, [user?.accessToken])

  const removeFavorite = async (entryId: string): Promise<boolean> => {
    if (!user?.accessToken) {
      return false
    }

    const item = favorites.get(entryId)

    if (!item) {
      return false
    }

    const removeFavoriteUrl = `${baseUrl}/api/v1/user/favorite/detail/${item.id}/`
    let response

    try {
      response = await axios.delete(removeFavoriteUrl, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
    } catch (e) {
      console.log(`addFavorite Error: `, e.message)
      response = null
    }

    if (response?.status === 204) {
      favorites.delete(entryId)
      setFavorites(new Map(favorites))
    }

    return true
  }

  const addFavorite = async (
    entryId: string,
    contentType: string
  ): Promise<boolean> => {
    if (!user?.accessToken) {
      return false
    }

    const addFavoriteUrl = `${baseUrl}/api/v1/user/favorite/create/`
    const postData = {
      content_type: contentType,
      entry_id: entryId
    }

    let response

    try {
      response = await axios.post(addFavoriteUrl, postData, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`
        }
      })
    } catch (e) {
      console.log(`addFavorite Error: `, e.message)
      response = null
    }

    if (!response) return false

    const result = response?.data as ResponseItem

    if (result.entry_id === entryId) {
      setFavorites(
        new Map(
          favorites.set(entryId, {
            id: result.id,
            content_type: contentType
          })
        )
      )

      return true
    }

    return false
  }

  const checkFavorite = (itemId: string): boolean => {
    return favorites.get(itemId)
  }

  return {
    addFavorite,
    checkFavorite,
    removeFavorite
  }
}
